//import "typeface-montserrat"
//import "typeface-merriweather"
//import "prismjs/themes/prism.css"
require("./src/scss/index.scss");
/*import React from 'react';
import client from './src/client/apolloClient';
import {ApolloProvider} from '@apollo/client';

export const wrapRootElement = ({element}) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);*/